<template>
  <div class="teacher-course-content container p-0">
    <div v-if="contentLevel === 0" class="teacher-course-content__navigation">
      <BackButton :label="$t('buttonLabels.backToCourse')" :routeToPush="routeBack" />
    </div>
    <div v-if="contentLevel === 0" class="teacher-course-content__title">
      {{ $t('buttonLabels.courseContent') }}
    </div>
    <div v-else>
      <CourseContentBreadCrumbs class="teacher-course-content__breadcrumb" />
    </div>

    <CourseContentDropdownButton class="teacher-course-content__dropdown" />

    <SequencedStepSwitcher
      :label="$t('supportText.getAccessToMaterial')"
      :value="!!isBlocking"
      updateKey
      @click.native.prevent="toggleHandler"
    />

    <div v-if="contentLevel !== 0" class="teacher-course-content__group-type">
      <input
        ref="group-type-input"
        v-model.trim="groupTitle"
        @change="changeGroupSubtitle"
        :placeholder="$t('placeholders.enterGroupType')"
      />
    </div>
    <div v-if="contentLevel !== 0" class="teacher-course-content__group-name">
      <input
        ref="group-name-input"
        v-model.trim="groupDescription"
        @change="changeGroupTitle"
        :placeholder="$t('placeholders.enterGroupName')"
      />
    </div>

    <div class="teacher-course-content__content content-block">
      <transition name="component-fade" mode="out-in">
        <div v-if="loading" class="content-block__loading">
          <div class="f-center">
            <div>
              <Loader />
            </div>
          </div>
        </div>

        <CourseContentEmpty v-else-if="!content.length" />
        <CourseContent v-else class="content-block__content" />
      </transition>
    </div>

    <component :is="currentModalComponent" @unmountModal="unmountModal" />

    <Statistic
      class="program-page__statistic"
      :class="{ 'slide-up': showStatistic }"
      :showStatistic.sync="showStatistic"
      :statisticTitle="statisticTitle"
      :statisticMeta="statisticMeta"
      :userBanList="userBanList"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BackButton from '@/components/Buttons/BackButton.vue';
import Loader from '@/components/Loader/Loader.vue';
import CourseContentBreadCrumbs from '@/components/Teacher/CourseContent/CourseContentBreadCrumbs.vue';
import CourseContentDropdownButton from '@/components/Teacher/CourseContent/CourseContentDropdownButton.vue';
import CourseContent from '@/components/Teacher/CourseContent/CourseContent.vue';
import CourseContentEmpty from '@/components/Teacher/CourseContent/CourseContentEmpty.vue';
import Statistic from '@/components/Statistics/Statistic.vue';
import SequencedStepSwitcher from '@/components/SequencedStepSwitcher.vue';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';

export default {
  name: 'CourseContentTeacher',
  components: {
    SequencedStepSwitcher,
    CourseContentEmpty,
    CourseContent,
    CourseContentDropdownButton,
    CourseContentBreadCrumbs,
    BackButton,
    Loader,
    Statistic,
  },
  data() {
    return {
      groups: [],
      groupTitle: '',
      groupDescription: '',
      programId: this.$route.params.programId,
      courseId: this.$route.params.courseId,
      course: null,
      loading: true,
      showStatistic: false,
      statisticMeta: {
        type: 'course',
        id: this.$route.params.courseId,
        title: '',
      },
      userBanList: [],
      currentModalType: '',
    };
  },
  provide() {
    return {
      openStatistic: this.openActivityStatistic,
    };
  },
  async created() {
    const groupIdOfLevelOne = this.$route.query?.groupIdOfLevelOne || null;
    const groupIdOfLevelTwo = this.$route.query?.groupIdOfLevelTwo || null;
    this.setGroupIdOfLevelOne(groupIdOfLevelOne);
    this.setGroupIdOfLevelTwo(groupIdOfLevelTwo);

    await this.fetchContent({ programId: this.programId, courseId: this.courseId })
      .then((data) => {
        this.course = data.activity;
        this.userBanList = data.activity.student_ids_ban_list;
      })
      .catch(console.log)
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters('courseContentTeacher', [
      'content',
      'contentLevel',
      'groupIdOfLevelOne',
      'groupIdOfLevelTwo',
      'selectedGroupOfLevelOne',
      'selectedGroupOfLevelTwo',
      'activitiesOfCurrentLevelContent',
    ]),
    ...mapGetters('modalType', ['getCurrentModalType']),

    routeBack() {
      return `/course/${this.programId}/${this.courseId}`;
    },
    combined() {
      return {
        groupIdOfLevelOne: this.groupIdOfLevelOne,
        groupIdOfLevelTwo: this.groupIdOfLevelTwo,
      };
    },
    statisticTitle() {
      return this.statisticMeta.title ? this.statisticMeta.title : this.title;
    },
    isBlocking: {
      get() {
        switch (this.contentLevel) {
          case 0:
            return !!this.course?.is_blocking;
          case 1:
            return !!this.selectedGroupOfLevelOne?.is_blocking;
          case 2:
            return !!this.selectedGroupOfLevelTwo?.is_blocking;
          default:
            return false;
        }
      },
      set(blocking) {
        switch (this.contentLevel) {
          case 0:
            this.course.is_blocking = blocking;
            if (blocking) {
              ActivityService.blockOrderOfActivity(this.courseId).catch(
                () => (this.course.is_blocking = !blocking),
              );
            } else {
              ActivityService.unblockOrderOfActivity(this.courseId).catch(
                () => (this.course.is_blocking = !blocking),
              );
            }
            return;
          case 1:
            return this.changeBlockActivityOrdering({
              id: this.selectedGroupOfLevelOne._id,
              blocking,
            });
          case 2:
            return this.changeBlockActivityOrdering({
              id: this.selectedGroupOfLevelTwo._id,
              blocking,
            });
          default:
            return null;
        }
      },
    },
    modalsComponents() {
      return {
        task: () => import('@/components/Modals/ModalTask.vue'),
        video: () => import('@/components/Modals/ModalVideo.vue'),
        material: () => import('@/components/Modals/ModalMaterial.vue'),
        'edit-material': () => import('@/components/Modals/ModalEditMaterial.vue'),
        quiz: () => import('@/components/Modals/ModalQuiz.vue'),
      };
    },
    currentModalComponent() {
      return this.modalsComponents[this.getCurrentModalType] || null;
    },
  },
  methods: {
    ...mapActions('modalType', ['resetCurrentModalType']),
    ...mapActions('courseContentTeacher', [
      'fetchContent',
      'reorderMaterials',
      'resetMaterialList',
      'setGroupIdOfLevelOne',
      'setGroupIdOfLevelTwo',
      'updateActivity',
      'changeBlockActivityOrdering',
    ]),
    resetContentLevel() {
      this.setGroupIdOfLevelOne(null);
      this.setGroupIdOfLevelTwo(null);
    },
    async changeGroupSubtitle(e) {
      this.$refs['group-type-input'].blur();
      this.$refs['group-name-input'].focus();
      const id = this.contentLevel === 1 ? this.groupIdOfLevelOne : this.groupIdOfLevelTwo;
      await this.updateActivity({ id, activity: { name: e.target.value } });
    },
    async changeGroupTitle(e) {
      this.$refs['group-name-input'].blur();
      const id = this.contentLevel === 1 ? this.groupIdOfLevelOne : this.groupIdOfLevelTwo;
      await this.updateActivity({ id, activity: { description: e.target.value } });
    },
    openActivityStatistic(type, id, title) {
      this.statisticMeta = {
        type,
        id,
        title,
      };
      this.showStatistic = true;
    },
    toggleHandler() {
      // for course
      let text = this.$t('supportText.unlockCourse');
      if (!this.isBlocking) {
        text = this.$t('supportText.lockCourse');
      }

      // for subgroup
      if (this.groupIdOfLevelTwo) {
        text = this.$t('supportText.unlockSubgroup');
        if (!this.isBlocking) {
          text = this.$t('supportText.lockSubgroup');
        }
        // for group
      } else if (this.groupIdOfLevelOne) {
        text = this.$t('supportText.unlockMaterials');
        if (!this.isBlocking) {
          text = this.$t('supportText.lockMaterials');
        }
      }

      this.$bvModal
        .msgBoxConfirm(text, {
          size: 'sm',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.yesEnable'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((bool) => {
          if (bool) {
            this.isBlocking = !this.isBlocking;
          }
        })
        .catch(console.log);
    },
    unmountModal() {
      this.resetCurrentModalType();
    },
  },
  beforeDestroy() {
    if (this.content.length) {
      this.resetMaterialList();
    }
    this.resetContentLevel();
  },
  watch: {
    combined: {
      handler(value) {
        const queryLevelOne = this.$route.query?.groupIdOfLevelOne || null;
        const queryLevelTwo = this.$route.query?.groupIdOfLevelTwo || null;
        if (
          value?.groupIdOfLevelOne === queryLevelOne
          && value?.groupIdOfLevelTwo === queryLevelTwo
        ) {
          return;
        }

        this.$router.replace({
          name: 'TeacherCourseContent',
          query: {
            groupIdOfLevelOne: value?.groupIdOfLevelOne,
            groupIdOfLevelTwo: value?.groupIdOfLevelTwo,
          },
        });
      },
    },
    selectedGroupOfLevelOne: {
      handler(v) {
        if (this.contentLevel === 1) {
          this.groupTitle = v?.name;
          this.groupDescription = v?.description;
        }
      },
      deep: true,
    },
    selectedGroupOfLevelTwo: {
      handler(v) {
        if (this.contentLevel === 2) {
          this.groupTitle = v?.name;
          this.groupDescription = v?.description;
        }
      },
      deep: true,
    },
    showStatistic: {
      handler(bool) {
        const showStatistic = () => {
          // eslint-disable-next-line no-unused-expressions
          const mainLayout = document.getElementById('mainLayout');
          mainLayout.scrollTop = 0;
          document.getElementById('mainLayout').classList.add('scrollHidden');
        };
        // eslint-disable-next-line no-unused-expressions
        bool
          ? setTimeout(showStatistic, 600)
          : document.getElementById('mainLayout').classList.remove('scrollHidden');
      },
    },
    currentModalComponent: {
      handler(modalComponent) {
        if (modalComponent) {
          modalComponent().then(() => {
            this.$nextTick().then(() => {
              this.$bvModal.show(`modal-${this.getCurrentModalType}`);
            });
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.content-block__content ::v-deep .list-group-item:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
