<template>
  <div class="group-card" @click="openGroup(group._id || group.activity._id)">
    <div class="group-card__subtitle">
      <p>{{ group.name || $t('label.groupType') }}</p>
      <div v-if="type === 'teacher'" class="group-card__dropdown">
        <b-dropdown size="sm" dropleft variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <BigOptionsButton />
          </template>
          <div class="dropdown__wrapper">
            <b-dropdown-item href="#" @click.stop="() => removeActivity(group._id)"
              >{{ $t('buttonLabels.delete') }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div class="group-card__title">{{ group.description || $t('label.newGroup') }}</div>
    <div class="group-card__bottom">
      <div>
        <div class="w-100">
          <span v-if="materialCount">{{ $tc('buttonLabels.materialPlural', materialCount) }}</span>
          <div v-if="type === 'student'" class="mt-2 d-flex justify-content-between">
            <ProgressStatus iconPosition="left" :status="status" />
            <ArrowRightSimpleIcon v-if="type === 'student'" />
          </div>
        </div>
        <ArrowRightSimpleIcon v-if="type !== 'student'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ArrowRightSimpleIcon from '@/components/Icons/ArrowRightSimpleIcon.vue';
import BigOptionsButton from '@/components/Buttons/BigOptionsButton.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import { doing, done, notStarted } from '@/utils/constants';
import { ActivityStatuses } from '../../../utils/statusConstants';

export default {
  name: 'CourseGroupCard',
  props: {
    group: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'teacher',
    },
    openGroup: {
      type: Function,
      required: true,
    },
    counter: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BigOptionsButton,
    ArrowRightSimpleIcon,
    ProgressStatus,
  },
  methods: {
    ...mapActions('courseContentTeacher', ['removeActivity']),
  },
  computed: {
    ...mapGetters('courseContentTeacher', ['contentOfTheGroupLength']),
    materialCount() {
      if (this.type === 'student') {
        return this.counter;
      }
      return this.contentOfTheGroupLength(this.group._id);
    },
    status() {
      if (this.group.statistic.status === ActivityStatuses.done) {
        return done;
      }
      if (this.group.statistic.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
  },
};
</script>
